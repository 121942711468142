/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, keywords, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaKeywords = keywords || ["dikafol", "installateur", "installateur in wien", "installateur in simmering", "wien", "simmering", "dikafol installateur"]

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: `https://www.dikafol.at`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: `dikafol`,
        },
        {
          name: `twitter:description`,
          content: `DIKAFOL Installateur in Wien Simmering | Ihr Installateur in Wien Simmering`,
        },
        {
          name: `twitter:image`,
          content: ``,
        },
        {
          itemprop: `name`,
          content: `dikafol`
        },
        {
          itemprop: `description`,
          content: `DIKAFOL Installateur in Wien Simmering | Ihr Installateur in Wien Simmering`
        },
        {
          itemprop: `image`,
          content: ``
        }
      ].concat(
          metaKeywords && metaKeywords.length > 0 ? {
            name: `keywords`,
            content: metaKeywords.join(`, `)
          } : []
      )}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
